import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/privacy/banner";
import PrivacyPolicy from "../components/privacy/privacypolicy";

const Privacy = (props) => (
    <Layout {...props}>
        <SEO title="Privacy" />
        <div class="col-12 wrapper main-bggrey p-0">
             <div class="col-12 inner-page-content home-inner px-0">
                  <Banner/>
             </div>
        </div>
        <PrivacyPolicy/>
    </Layout>
)

export default Privacy;