import React from 'react';
import singlecbg from "../../assets/images/single-c-bg.png"
import { StaticQuery, graphql } from "gatsby"

const Banner = () => (
    <StaticQuery
         query={graphql`
            query {
                allWpPage(filter: {slug: {eq: "privacy-policy"}}) {
                    edges {
                      node {
                        slug
                        TeamlancePrivacyPolicy {
                          data {
                            title
                            description
                          }
                          welcomeToTeamlance {
                            title
                            description
                          }
                          informationYouProvideThroughYourAccount {
                            title
                            description
                          }
                          users {
                            title
                            description
                          }
                        }
                        content
                        title
                        isPrivacyPage
                      }
                    }
                  }
            }
         `}
         render={data => {
             return(
                data && data.allWpPage &&
                data.allWpPage.edges &&
                data.allWpPage.edges[0] &&
                data.allWpPage.edges[0].node &&
                <section class="quote-bn">
                    <div class="bn-bg quote-bg">
                        <img src={singlecbg} alt="case study" />
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12  d-flex flex-column sectiontop-pd  ">
                                <div class=" quote-banner text-center case-heading my-auto ml-0 ">
                                    <h3 class="">{data.allWpPage.edges[0].node.title?data.allWpPage.edges[0].node.title: "Privacy Policy" }</h3> 
                                    {data.allWpPage.edges[0].node.content?
                                    <p dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.content}}>
                                    </p>:
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac purus enim nisl risus. <br />Hendrerit ut purus aliquet feugiat a morbi vitae sollicitudin.
                                    </p>}
                                </div>
                            </div>
                        </div> 
                    </div>
                </section>
             )
            
         }}
     />
    
)

export default Banner;