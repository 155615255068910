import React from 'react';
import { StaticQuery, graphql } from "gatsby"

const PrivacyPolicy = () => (
   <StaticQuery
         query={graphql`
            query {
                allWpPage(filter: {slug: {eq: "privacy-policy"}}) {
                    edges {
                      node {
                        slug
                        TeamlancePrivacyPolicy {
                           teamlancePrivacyPolicy {
                              title
                              topics {
                                link
                                title
                              }
                          }
                          data {
                            title
                            description
                          }
                          welcomeToTeamlance {
                            title
                            description
                          }
                          informationYouProvideThroughYourAccount {
                            title
                            description
                          }
                          users {
                            title
                            description
                          }
                        }
                        content
                        title
                        isPrivacyPage
                      }
                    }
                  }
            }
         `}
         render={data => {
             return(
               data && data.allWpPage &&
               data.allWpPage.edges &&
               data.allWpPage.edges[0] &&
               data.allWpPage.edges[0].node &&
               data.allWpPage.edges[0].node.TeamlancePrivacyPolicy &&
               <section className="share-sec">
               <div className="share-top">
                  <svg width="366" height="185" viewBox="0 0 366 185" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M51.8221 167.583L-35.3348 87.7328L-40 34.2603L358.287 64.1671L83.1531 173.347C72.4676 177.588 60.2986 175.349 51.8221 167.583Z" fill="#D8F4FF"></path>
                  </svg>
               </div>
               <div className="container my-5 ">
                  <div className="row privacy-wrapper">
                     <div className="col-md-4 pc-left">
                        {data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.teamlancePrivacyPolicy&&
                        <div className="side-pc">
                           <h3 className="mobile-hidden">{data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.teamlancePrivacyPolicy.title}</h3>
                           <h3 className="desktop-hidden">Topics</h3>
                           <div className="desktop-hidden pc-menu">
                              <select className="form-select" aria-label="Default select example">
                                 {
                                   data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.teamlancePrivacyPolicy.topics
                                   &&data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.teamlancePrivacyPolicy.topics.length>0
                                   &&data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.teamlancePrivacyPolicy.topics.map((topic,key)=>{
                                        return(
                                          <option value={key} key={key}>{topic.title}</option>
                                        )
                                   })
                                    
                                 }
                              </select>
                              <span>
                                 <svg className="sl-arow" width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.5625 0.875L5.5 4.9375L1.4375 0.875" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 </svg>
                              </span>
                           </div>
                           <ul className="mobile-hidden">
                                  {data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.teamlancePrivacyPolicy.topics
                                   &&data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.teamlancePrivacyPolicy.topics.length>0
                                   &&data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.teamlancePrivacyPolicy.topics.map((topic,key)=>{
                                       return(
                                          <li key={key}><a href={topic.link}>{topic.title}</a></li>
                                       )
                                   })}
                           </ul>
                        </div>}
                     </div>
                     <div className="col-md-8 pc-right">
                        <div className="privacy-content">
                           <div className="privacy-header">
                              <h1>Teamlance Privacy Policy</h1>
                              <p><span className="pc-blue">Effective Date:</span> November 19, 2020 </p>
                           </div>
                           {data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.welcomeToTeamlance &&
                             <div className="bordered-sec pc-inner">
                              <h4>{data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.welcomeToTeamlance.title}</h4>
                              <span dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.welcomeToTeamlance.description }}></span>
                            </div>}
                           {data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.informationYouProvideThroughYourAccount &&
                            <div className="bordered-sec pc-inner">
                              <h4>{data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.informationYouProvideThroughYourAccount.title}</h4>
                              <span dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.informationYouProvideThroughYourAccount.description }}></span>
                           </div>}
                           {data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.users &&<div className="bordered-sec pc-inner">
                              <h4>{data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.users.title}</h4>
                              <span dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.users.description }}></span>
                           </div>}
                           {data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.data &&<div className="bordered-sec pc-inner">
                              <h4>{data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.data.title}</h4>
                              <span dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.TeamlancePrivacyPolicy.data.description }}></span>
                           </div>}
                        </div>
                     </div>
                  </div>
               </div>
             </section>
             )
         }}
      />
  
)

export default PrivacyPolicy;